import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, pipe } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {AppService} from './app.service';
import {AuthService} from './auth.service';
import {UserService} from './user.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private http: HttpClient,
              private authService: AuthService,
              private userService: UserService,
              private appService: AppService) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const authServiceApi = environment.authServiceApi;
    const home = environment.home;
    const internalHome = environment.internal_home;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
    };

    // skip api hit if token is valid
    if (this.authService.isAuthenticated()) {
      return true;
    }

    const observable = this.http.get<any>(authServiceApi + 'user', httpOptions).pipe(
      map(result => {
        if (result) {
          this.appService.setUserLoggedIn(true);
          const userAuthentication = result.userAuthentication;
          const tokenValue = result.details.tokenValue;
          const details = userAuthentication.details;
          const identities = details.identities[0];

          this.userService.getUser().accessToken = tokenValue;
          this.userService.getUser().fname = identities.idpUserInfo.attributes.Firstname;
          this.userService.getUser().lname = identities.idpUserInfo.attributes.Lastname;
          this.userService.getUser().name = identities.idpUserInfo.attributes.name;
          this.userService.getUser().locale = identities.idpUserInfo.attributes.locale.replace(/^0+/, '');
          this.userService.getUser().idpemail = identities.idpUserInfo.attributes.email;
          this.userService.getUser().user = userAuthentication.name;
          this.userService.getUser().email = details.email;
          return true;
        } else {
          this.userService.clearUser();
          console.log('not authenticated');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        console.log(err);
        this.appService.setUserLoggedIn(false);
        this.userService.clearUser();
        // window.open(authServiceApi + '?r=' + home,'_self');
        window.open(authServiceApi + '?r=' + window.location.href, '_self');
        return of(false);
      }),
    );
    return observable;
  }
}
