import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AppService {

  private userLoggedIn = new Subject<boolean>();
  private tokenExpTime = new Subject<number>();

  constructor() {
    this.userLoggedIn.next(false);
  }

  public setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  public getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  public setTokenExpiration(tokenExpTime: number) {
    this.tokenExpTime.next(tokenExpTime);
  }

  public getTokenExpiration(): Observable<number> {
    return this.tokenExpTime.asObservable();
  }
}
