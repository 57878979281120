import {
  HttpHandler,
  HttpHeaderResponse,
  HttpHeaders,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AuthServiceInterceptorService implements HttpInterceptor {

  public headerEntry: any;
  public headers: HttpHeaders;

  constructor() { }

  public intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    if (req.url.indexOf('/api') !== -1) {
      return next.handle(req);
    }

    const nextReq = req.clone({
      withCredentials: true,
    });

    return next.handle(nextReq);
  }
}
