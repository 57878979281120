import { NgModule } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  declarations: [],
  exports: [
    MatTabsModule,
  ],
})
export class MaterialModule { }
