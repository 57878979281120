import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DATUpdateResponse } from '../model/datupdateresponse.model';
import { Employee } from '../model/employee.model';
import { AuthService } from '../service/auth.service';
import { EmployeeService } from '../service/employee.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-emp-tabgroup-details',
  styleUrls: ['./emp-tabgroup-details.component.css'],
  templateUrl: './emp-tabgroup-details.component.html',
})

export class EmpTabgroupDetailsComponent implements OnInit, OnDestroy {

  public navigationSubscription;

  employee: Employee;
  datUpdateResponse: DATUpdateResponse;
  username: string;
  email: string;
  isLoggedIn = false;
  isDatUpdatedByUser = false;
  DATformDiv = false;
  DATmessageDiv = false;
  buttondisplay = false;
  message =  '';
  inside = '';
  reelectDat = false;
  dat = 0;
  validation: boolean;
  validateAccrued: boolean;

  datPrevDefault = 0;


  dats: any = {
    0: 'Reserve days',
    1: 'Reserve 0 days',
  };

  /*reserves: any = {
    0: ['Reserve 5 days', 'Reserve 10 days', 'Reserve 15 days'],
    1: ['Reserve 0 days'],
  };*/

  reserveDays = 5;

  reserveDaysList: any = {
    5: 'Reserve 5 days / 40 hours', 10: 'Reserve 10 days / 80 hours', 15: 'Reserve 15 days / 120 hours'
  };

  /*noReserveDays: number = 0;

  zeroReserve: any = {
    0: 'Reserve 0 days'
  };*/

  currentVacationYear = new Date().getFullYear();
  displaySelectionConfirmation = false;
  reelectDatSelected = false;
  datSelected = false;
  noDaysSelected = false;
  noChoiceSelected = false;
  displayAvailableDaysConfirmation = false;
  displayUpdateDatStatus = false;
  datUpdateResponseFailed = false;
  tempVacDatSltdDays = 0;

  prevDATdaysMessage = '';

  constructor(private router: Router,
              private employeeService: EmployeeService,
              private authService: AuthService,
              private userService: UserService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.getAndInitEmployeeDetails();
        // rest all values
        this.cancelUpdateDat();
      }
    });
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isAuthenticated();
    this.username = this.userService.getUser().locale;
    this.email = this.userService.getUser().email;
    this.getAndInitEmployeeDetails();
    this.validation = true;
  }

  public getAndInitEmployeeDetails() {
    this.employeeService.hideHomeButton();
    this.employeeService.getEmployeeById(this.username)
      .subscribe(data => {
        console.log(data);
        this.employee = data as Employee;
        if(this.employee.gpmsEmplId !== null)
        this.employee.gpmsEmplId = this.employee.gpmsEmplId.trim();
        this.employee.email = this.email;

        this.tempVacDatSltdDays = this.employee.datElection.vacDatSltdDays;

        if (this.employee.datElection.currentVacDatSltdInd != null
          && this.employee.datElection.currentVacDatSltdInd.trim() === '') {
          this.employee.datElection.vacDatSltdInd = 'N';
          this.employee.datElection.histCallTrmnCode = 'C';
          this.employee.datElection.vacDatSltdDays = this.employee.datElection.currentVacDatSltdDays;

          this.employeeService.updateEmployee(Number(this.employee.gpmsEmplId), this.employee).subscribe((data) => {
            console.log('updateDAT ' + data);
            this.datUpdateResponse = data as DATUpdateResponse;
          }, (error) => {
            console.log('Update DAT failed ' + error);
            this.datUpdateResponseFailed = true;
          });
        }
        this.employee.datElection.vacDatSltdDays = this.tempVacDatSltdDays;
        this.datPrevDefault = this.employee.datElection.vacDatSltdDays;
        this.populateEmployeeDetails();

        if(this.employee.datElection.vacDatSltdDays > this.employee.datElection.daysAccruedVacation){
          this.validateAccrued = true;
        }else{
          this.validateAccrued = false;
        }

      }, error => console.log(error));
  }

  public populateEmployeeDetails(): void {
    console.log(`before-recordHistoryForNoAction-gpmsEmplId:${this.employee.gpmsEmplId}`);
    if (document.cookie.indexOf(environment.record) === -1 && (this.employee.gpmsEmplId)) {
      this.authService.setCookie(environment.record, 'true', 1, 1);
      this.recordHistoryForNoAction();
    }

    if (this.employee.datElection.isDATEnabled
      && this.employee.datElection.isDATAvailable
      && this.employee.datElection.isDATValidForEmployee
      && this.employee.datElection.isDATEligibleForEmployee
      && this.employee.datElection.daysAccruedVacation >= 5) {

      this.DATformDiv = true;
      this.DATmessageDiv = false;

    } else {
      this.DATformDiv = false;
      this.DATmessageDiv = true;
      if (this.employee.error === "DBdown") {
        this.message = 'System not available.'
        + ' Contact Maintenance Administration at TechOps.DAT@aa.com';
      } else if (this.employee.gpmsEmplId === null || this.employee.gpmsEmplId === '') {
          this.message = 'Not a valid profile in the system.'
            + ' Contact Maintenance Administration at TechOps.DAT@aa.com';
      } else if (!this.employee.datElection.isDATEnabled || !this.employee.datElection.isDATAvailable )  {
        this.message = 'I\'m Sorry. The Day-At-A-Time vacation selection system is currently unavailable';
      } else if (!this.employee.datElection.isDATValidForEmployee) {
        this.message = 'You are not valid for DAT. If you are unable to make an election,'
            + ' or you believe the vacation information is incorrect, you may contact Maintenance'
            + ' Administration at TechOps.DAT@aa.com';
      } else if (this.employee.datElection.vacDatSltdInd === null && this.employee.datElection.vacShiftInd === null) {
        this.message = 'Hello ' + this.employee.firstName + ', YOU ARE NOT VALID FOR DAT.'
            + ' If you believe the vacation information is incorrect,'
            + ' you may contact Maintenance Administration at TechOps.DAT@aa.com';
      } else if (!this.employee.datElection.isDATEligibleForEmployee
          || this.employee.datElection.daysAccruedVacation < 5) {
        this.message = 'Hello ' + this.employee.firstName + ' you have '
            + this.employee.datElection.daysAccruedVacation
            + ' days accrued vacation which is less than the 5 days required to be eligible.'
            + ' If you believe the vacation information is incorrect,'
            + ' you may contact Maintenance Administration at TechOps.DAT@aa.com';
      }

    }

    if (this.employee.datElection.vacDatSltdDays === 0) {
      this.prevDATdaysMessage = 'You’ve previously elected not to reserve DAT days...';
    } else {
      this.prevDATdaysMessage = 'You’ve previously elected to reserve ' +
        this.employee.datElection.vacDatSltdDays + ' DAT days / ' + this.employee.datElection.vacDatSltdDays * 8 + ' DAT hours...';
    }

    if (this.employee.datElection.daysAccruedVacation >= 5
      && this.employee.datElection.daysAccruedVacation < 10) {
      this.reserveDaysList = {5: 'Reserve 5 days / 40 hours'};
    } else if (this.employee.datElection.daysAccruedVacation >= 10
      && this.employee.datElection.daysAccruedVacation < 15) {
      this.reserveDaysList = {5: 'Reserve 5 days / 40 hours', 10: 'Reserve 10 days / 80 hours'};
    } else if (this.employee.datElection.daysAccruedVacation >= 15) {
      this.reserveDaysList = {5: 'Reserve 5 days / 40 hours', 10: 'Reserve 10 days / 80 hours', 15: 'Reserve 15 days / 120 hours'};
    }

    if (this.employee.datElection.currentVacDatSltdInd !== 'Y') {
      this.reelectDat = true;
    }

    if (this.reelectDat !== true && this.employee.datElection.currentVacDatSltdDays === 0) {
      this.dat = 1;
    } else if (this.employee.datElection.currentVacDatSltdDays === 5
      || this.employee.datElection.currentVacDatSltdDays === 10
      || this.employee.datElection.currentVacDatSltdDays === 15) {
      this.reserveDays = this.employee.datElection.currentVacDatSltdDays;
    }
  }

  vacDatSltdDaysVSdaysAccruedVacation() {
    if (this.reelectDat
      && (this.employee.datElection.vacDatSltdDays > this.employee.datElection.daysAccruedVacation)) {
        this.DATformDiv = false;
        this.displayAvailableDaysConfirmation = true;
    } else {
      this.displaySelectionConfirmation = true;
      if (!this.reelectDat) {
        if (this.dat == 0) { // reserve days
          this.datSelected = true;
        } else if (this.dat == 1) { // reserve 0 days
          this.noDaysSelected = true;
        }
      }
    }
  }

  goback() {
    this.DATformDiv = true;
    this.DATmessageDiv = false;
    this.reelectDat = false;
  }

  resetform() {
    this.reelectDat = false;
    this.dat = 0;
    this.reserveDays = 5;
  }

  submitDatSelection() {
    if (this.reelectDat
      && (this.employee.datElection.vacDatSltdDays > this.employee.datElection.daysAccruedVacation)) {
        this.validation = false;
      }else{
        if (this.reelectDat){
          if(this.employee.datElection.vacDatSltdDays < 5){
            this.employee.datElection.vacDatSltdDays = 0;
          }else  if(this.employee.datElection.vacDatSltdDays < 10){
            this.employee.datElection.vacDatSltdDays = 5;
          }else  if(this.employee.datElection.vacDatSltdDays < 15){
            this.employee.datElection.vacDatSltdDays = 10;
          }else{
            this.employee.datElection.vacDatSltdDays = 15;
          }
        }
        this.vacDatSltdDaysVSdaysAccruedVacation();
      }
  }

  cancelUpdateDat() {
    this.employee.datElection.vacDatSltdDays = this.datPrevDefault
    this.displayUpdateDatStatus = false;
    this.displaySelectionConfirmation = false;
    this.displayAvailableDaysConfirmation = false;
    this.datSelected = false;
    this.noDaysSelected = false;
    this.noChoiceSelected = false;
    this.DATformDiv = true;
  }

  updateDatwithDaysAccruedVacation() {
    this.employee.datElection.histCallTrmnCode = 'C';
    if (this.reelectDat) {
      this.employee.datElection.vacDatSltdDays = this.employee.datElection.daysAccruedVacation;
      this.employee.datElection.vacDatSltdInd = 'Y';
      this.employee.datElection.elecCode = 'Y';
    }
    this.displayUpdateDatStatus = true;
    this.displaySelectionConfirmation = false;
    this.displayAvailableDaysConfirmation = false;

    this.employeeService.displayHomeButton();

    this.employeeService.updateEmployee(Number(this.employee.gpmsEmplId), this.employee).subscribe((data) => {
      console.log('updateDAT ' + data);
      this.datUpdateResponse = data as DATUpdateResponse;
    }, (error) => {
      console.log('Update DAT failed ' + error);
      this.datUpdateResponseFailed = true;
    });
  }

  updateDat() {
    console.log('Update DAT ' + this.username);
    // set histCallTrmnCode for all transaction
    this.employee.datElection.histCallTrmnCode = 'C';

    if (this.reelectDat) {
      this.employee.datElection.vacDatSltdInd = 'Y';
      this.employee.datElection.elecCode = 'Y';
    } else if (this.datSelected) { // reserve days
      this.employee.datElection.isReserveDaysSelected = true;
      this.employee.datElection.vacDatSltdDays = this.reserveDays;
      this.employee.datElection.vacDatSltdInd = 'Y';
      this.employee.datElection.elecCode = 'Y';
    } else if (this.noDaysSelected) { // reserve 0 days
      this.employee.datElection.isReserveNoDaysSelected = true;
      this.employee.datElection.vacDatSltdDays = 0;
      this.employee.datElection.vacDatSltdInd = 'Y';
      this.employee.datElection.elecCode = 'Y';
    }

    this.displayUpdateDatStatus = true;
    this.displaySelectionConfirmation = false;
    this.displayAvailableDaysConfirmation = false;

    this.isDatUpdatedByUser = true;
    this.employeeService.displayHomeButton();

    this.employeeService.updateEmployee(Number(this.employee.gpmsEmplId), this.employee).subscribe((data) => {
      console.log('updateDAT ' + data);
      this.datUpdateResponse = data as DATUpdateResponse;
    }, (error) => {
      console.log('Update DAT failed ' + error);
      this.datUpdateResponseFailed = true;
    });

  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  public recordHistoryForNoAction(): void {
      this.employee.datElection.histCallTrmnCode = 'Q';
      this.employee.datElection.elecCode = 'Q';

      this.employeeService.recordHistory(Number(this.employee.gpmsEmplId), this.employee).subscribe((data) => {
        console.log('Record History ' + data);
      }, (error) => {
        console.log('Record History failed ' + error);
      });
  }

  public ngOnDestroy() {
    this.navigationSubscription.unsubscribe();
  }

  CloseErrorModal() {
    this.validation = true;
  }
}
