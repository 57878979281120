import { Injectable } from '@angular/core';
import {User} from '../model/user.model';


@Injectable()
export class UserService {
    private user: User;

    public getUser(): User {
        if (!this.user) {
            this.user = new User();
        }
        return this.user;
    }

    public clearUser(): void {
        this.user = null;
    }
}
