import { DOCUMENT } from '@angular/common';
import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import { Employee } from '../model/employee.model';
import { AuthService } from '../service/auth.service';
import { EmployeeService } from '../service/employee.service';

@Component({
  selector: 'app-employee-details',
  styleUrls: ['./employee-details.component.css'],
  templateUrl: './employee-details.component.html',
})

export class EmployeeDetailsComponent implements AfterViewChecked, OnInit, OnDestroy {

  public id: number;
  public employee: Employee;
  public isLoggedIn = false;
  public displayHomeButton: false;

  public navigationSubscription;
  public datUpdatedEventSubscription: Subscription;

  constructor(@Inject(DOCUMENT) private document: Document,
              private router: Router,
              private employeeService: EmployeeService,
              private authService: AuthService,
              private cdRef: ChangeDetectorRef) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.getLoggedInEmployeeDetails();
      }
    });

    this.datUpdatedEventSubscription = this.employeeService.getHomeButtonEmittedValue().subscribe((item) => this.displayHomeButton = item);
  }

  public ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.isLoggedIn = true;
      this.getLoggedInEmployeeDetails();
    } else {
      console.log('User is not authenticated');
      this.handleLogout();
    }
  }

  public getLoggedInEmployeeDetails() {
    this.employeeService.getEmployeeDetails()
      .subscribe((data) => {
        console.log(data);
        this.employee = data as Employee;
      }, (error) => {
        console.log('Got exception in employee details component getLoggedInEmployeeDetails ');
        console.log(error);
      });

  }

  public handleLogout() {
    this.authService.logout();
  }

  public ngAfterViewChecked() {
      this.cdRef.detectChanges();
  }
  public ngOnDestroy() {
    this.navigationSubscription.unsubscribe();
    this.datUpdatedEventSubscription.unsubscribe();
  }
}
