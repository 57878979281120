import { HttpClient, HttpHeaders } from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root',
})

export class EmployeeService {

  public baseURL = environment.url;

  @Output() public homeButtonEventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private userService: UserService) { }

  public getEmployeeById(username: string): Observable<object> {
    const retrieveEmpDetailsURL = this.baseURL + '/empById/' + username;
    const reqHeaders = new HttpHeaders();
    reqHeaders.append('Content-Type', 'application/json');
    return this.http.get(retrieveEmpDetailsURL);
  }

  public getEmployeeDetails(): Observable<object> {
    const retrieveEmpDetailsURL = `${this.baseURL}` + '/empById/' + this.userService.getUser().locale;
    const reqHeaders = new HttpHeaders();

    reqHeaders.append('Content-Type', 'application/json');

    return this.http.get(retrieveEmpDetailsURL);
  }

  public updateEmployee(id: number, value: any): Observable<object> {
    return this.http.put(`${this.baseURL}/datUpdate/${id}`, value);
  }

  public recordHistory(id: number, value: any): Observable<object> {
    return this.http.put(`${this.baseURL}/recordHistory/${id}`, value);
  }

  public hideHomeButton() {
    this.homeButtonEventEmitter.emit(true);
  }

  public displayHomeButton() {
    this.homeButtonEventEmitter.emit(false);
  }

  public getHomeButtonEmittedValue() {
    return this.homeButtonEventEmitter;
  }
}
