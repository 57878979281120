import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { interval } from 'rxjs';
import { AppService } from './service/app.service';
import { AuthService } from './service/auth.service';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public idleState = 'Not started.';
  public timedOut = false;
  public lastPing?: Date = null;
  public title = 'DAT Election';

  constructor(private idle: Idle,
              private keepalive: Keepalive,
              private router: Router,
              private appService: AppService,
              public authService: AuthService) {

    // Initially sets an idle timeout of 15 mins (900 sec - 5 sec below idle timeout)
    idle.setIdle(895);

    // once get tokenExpTime from SSO reset the idle time
    // this.appService.getTokenExpiration().subscribe( (tokenExpTime) => {
    //   idle.setIdle(tokenExpTime);
    // });

    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      console.log(this.idleState);
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.appService.getUserLoggedIn().subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        idle.watch();
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });

  }

  public ngOnInit(): void {
    const sessionTimout = interval(60 * 60 * 1000);
    sessionTimout.subscribe(() => {
      console.log('session timeout');
      this.logout();
    });

    // notify activity
    this.authService.callPing();

    /*const source = interval(5 * 60 * 1000);
    this.authService.sessionCheck = source.subscribe(() => {
      console.log('session check');
      if (!this.authService.isAuthenticated()) {
        console.log('session timeout, refreshing page to get token');
        location.reload();
      }

      setTimeout(() => this.authService.callInactivityCheck(), 0);
    });

    this.authService.userActivityTimeout();
    this.authService.userInactive.subscribe(() => {
      console.log('notify user is active');
      this.authService.callPing();
    });*/
  }

  public reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  public displayLogo() {
    return this.router.url !== '/';
  }

  public logout() {
    this.authService.logout();
  }

  /*@HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    (window as any).onhelp = function() { return false; };
    this.authService.resetActivity();
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.authService.resetActivity();
  }*/

}
