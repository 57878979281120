export class User {
    accessToken: string;
    fname: string;
    lname: string;
    name: string;
    locale: string;
    idpemail: string;
    user: string;
    email: string;
}
