import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {EmpTabgroupDetailsComponent} from './emp-tabgroup-details/emp-tabgroup-details.component';
import {MaterialModule} from './emp-tabgroup-details/material/material.module';
import {EmployeeDetailsComponent} from './employee-details/employee-details.component';
import {AuthGuardService} from './service/auth-guard.service';
import {HttpInterceptorService} from './service/http-interceptor.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AuthService} from './service/auth.service';
import {UserService} from './service/user.service';
import {AuthServiceInterceptorService} from './service/auth-service-interceptor.service';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    EmployeeDetailsComponent,
    EmpTabgroupDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    AuthGuardService,
    AuthService,
    UserService,
    JwtHelperService,
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: AuthServiceInterceptorService,
    },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class AppModule { }
