// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
const api = 'https://svc.datelection-stage.aa.com/api/v1';
const home =  'https://datelection-stage.aa.com';
const authService =  'https://auth.datelection-stage.aa.com/';


export const environment = {
  authService,
  authServiceApi: authService + 'auth/',
  home,
  internal_home : home,
  local_dev_mode : false, // for local development, dont use https, dont send token headers
  ping: 10,
  production: false,
  record: 'datRecord',
  url : api,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
