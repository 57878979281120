import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root',
})

export class HttpInterceptorService implements HttpInterceptor {

  public headerEntry: any;
  public headers: HttpHeaders;

  constructor(private authenticationService: AuthService,
              private router: Router,
              private userService: UserService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.indexOf('/auth') !== -1) {
      return next.handle(req);
    }

    if (this.authenticationService.isAuthenticated()) {
      this.prepareHeader();
      const authReq = req.clone({headers: this.headers,  withCredentials: true});
      console.log(authReq);
      return next.handle(authReq);
    } else {
      // Create error message and pass it to welcome page. "Authentication token expired. Please sign in again"
      this.router.navigate(['details']);
      return next.handle(req);
    }
  }

  public prepareHeader() {
    this.headerEntry = {
      'Content-Type': 'application/json',
      timeout: 360000,
    };
    console.log('local_dev_mode : ' + environment.local_dev_mode);
    if (!environment.local_dev_mode) {
      const jwtok = this.userService.getUser().accessToken;
      /*console.log('jwtok');
      console.log(jwtok);*/
      this.headerEntry.Authorization = 'Bearer ' + jwtok;
    }
    this.headers = new HttpHeaders(this.headerEntry);
  }
}
