import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, Subject, Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {AppService} from './app.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  private jwtHelper: JwtHelperService = new JwtHelperService();
  pingApi: Observable<any>;
  inactiveCheckApi: Observable<any>;
  ping = environment.ping;
  sessionCheck: Subscription;
  userActivity;
  userInactive: Subject<any> = new Subject();
  serviceApi = environment.authServiceApi;

  USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';
  USER_JWT_AUTH_TOKEN = 'authenticatedUserJWT';

  private subject = new Subject<any>();

  constructor(private http: HttpClient,
              private userService: UserService,
              private router: Router,
              private appService: AppService) {

    const httpOptions = {
      withCredentials: true,
      responseType: 'text' as 'text',
    };

    this.pingApi = this.http.get(this.serviceApi + 'ping', httpOptions);
    this.inactiveCheckApi = this.http.get(this.serviceApi + 'inactiveCheck', httpOptions);
  }

  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.userService.getUser().accessToken);
  }

  resetActivity() {
    this.clearUserActivityTimeout();
    this.userActivityTimeout();
  }

  userActivityTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(), this.ping * 1000);
  }

  clearUserActivityTimeout() {
    clearTimeout(this.userActivity);
  }

  clearUserActivity() {
    clearTimeout(this.userActivity);
    this.userInactive.complete();
    if (this.sessionCheck) {
      this.sessionCheck.unsubscribe();
    }
  }

  callPing() {
    this.pingApi.subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.log(err);
      },
    );

  }

  callInactivityCheck() {
    this.inactiveCheckApi.subscribe(
      data => {
        const isTrueSet = (data == 'true');
        if (isTrueSet) {
          this.router.navigate(['/logout']);
        }
      },
      err => {
        console.log(err);
      },
    );
  }

  logout() {
    this.appService.setUserLoggedIn(false);
    this.subject.next();
    this.userService.clearUser();
    this.eraseCookie(environment.record);
    window.top.location.href =  environment.authService + 'logout';
  }

  setCookie(name, value, days, hours) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * hours * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '')  + expires + '; path=/';
  }

  eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }

  getLogoutEvent(): Observable<any> {
    return this.subject.asObservable();
  }

}
